<!--
 * @Description: 添加价格
 * @Author: ChenXueLin
 * @Date: 2021-11-09 18:26:29
 * @LastEditTime: 2022-07-15 16:38:03
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    :title="addType == 1 ? '新增价格体系' : '编辑价格体系'"
    :visible.sync="visible"
    width="85%"
    :before-close="closeDialog"
    :close-on-click-modal="false"
    append-to-body
    v-loading="dialogLoading"
    :element-loading-background="loadingBackground"
    custom-class="add-concat-dialog"
    @open="openDialog"
  >
    <el-tabs v-model="activeName">
      <el-tab-pane label="价格体系" name="1">
        <div class="dialogTitle">
          <div class="line"></div>
          <div class="til">基础信息</div>
        </div>
        <el-form
          ref="basicInfoForm"
          label-width="110px"
          :model="basicInfoForm"
          :inline="true"
          :rules="basicInfoFormRules"
        >
          <el-row>
            <el-col :span="10">
              <el-form-item label="服务商名称:" prop="serviceNo">
                <e6-vr-select
                  v-model="basicInfoForm.serviceNo"
                  placeholder="归属服务商"
                  title="归属服务商"
                  clearable
                  :data="facilitatorList"
                  :props="{
                    id: 'serviceNo',
                    label: 'serviceProvider'
                  }"
                  @change="changeFacilitator"
                ></e6-vr-select>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="服务商编号:" prop="serviceNo">
                <el-input
                  disabled
                  v-model="basicInfoForm.serviceNo"
                  placeholder="服务商编号"
                  title="服务商编号"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="飞书合同编号:" prop="feiShuContractNo">
                <el-input
                  v-model="basicInfoForm.feiShuContractNo"
                  placeholder="飞书合同编号"
                  title="飞书合同编号"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="14">
              <el-form-item label="生效周期:" prop="time">
                <e6-date-picker
                  popper-class="no-atTheMoment"
                  v-model="basicInfoForm.time"
                  :speedy="false"
                  type="date"
                  value-format="yyyy-MM-dd"
                  style="width:280px;"
                ></e6-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="支付主体:" prop="companyIdList">
                <e6-vr-select
                  multiple
                  v-model="basicInfoForm.companyIdList"
                  :data="companyList"
                  placeholder="支付主体"
                  ref="companySelect"
                  title="支付主体"
                  clearable
                  virtual
                  :is-title="true"
                  :props="{
                    id: 'codeValue',
                    label: 'codeName'
                  }"
                  @change="changeCompany"
                  @clear="clearCompany"
                ></e6-vr-select>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="服务费税率(%):" prop="serviceRate">
                <el-input
                  v-model="basicInfoForm.serviceRate"
                  placeholder="服务费税率(%)"
                  title="服务费税率(%)"
                  @input="limitInput($event, 'serviceRate')"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <div class="dialogTitle">
          <div class="line"></div>
          <div class="til">规则配置</div>
        </div>
        <el-form
          ref="serviceRuleForm"
          label-width="110px"
          :model="serviceRuleForm"
          :inline="true"
          :rules="serviceRuleFormRules"
        >
          <el-form-item label="拆机费规则:" prop="unpackFeeRule">
            <e6-vr-select
              v-model="serviceRuleForm.unpackFeeRule"
              :data="basicPriceRuleTypeList"
              placeholder="拆机费规则"
              title="拆机费规则"
              clearable
              :props="{
                id: 'codeValue',
                label: 'codeName'
              }"
            ></e6-vr-select>
          </el-form-item>
          <el-form-item label="维修费规则:" prop="repairFeeRule">
            <e6-vr-select
              v-model="serviceRuleForm.repairFeeRule"
              :data="basicPriceRuleTypeList"
              placeholder="维修费规则"
              title="维修费规则"
              clearable
              :props="{
                id: 'codeValue',
                label: 'codeName'
              }"
            ></e6-vr-select>
          </el-form-item>
          <el-form-item
            label="拆机单_ 拆机费:"
            prop="unpackAndUnpackFee"
            v-show="serviceRuleForm.unpackFeeRule == 1"
          >
            <el-input
              v-model="serviceRuleForm.unpackAndUnpackFee"
              placeholder="拆机单_ 拆机费"
              title="拆机单_ 拆机费"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="维修费:"
            prop="repairFee"
            v-show="serviceRuleForm.repairFeeRule == 1"
          >
            <el-input
              v-model="serviceRuleForm.repairFee"
              placeholder="维修费"
              title="维修费"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="非拆机单_ 拆机费:"
            prop="notUnpackAndUnpackFee"
            v-show="serviceRuleForm.unpackFeeRule == 1"
          >
            <el-input
              v-model="serviceRuleForm.notUnpackAndUnpackFee"
              placeholder="非拆机单_ 拆机费"
              title="非拆机单_ 拆机费"
            ></el-input>
          </el-form-item>
          <el-form-item label="夜间费:" prop="nightFee">
            <el-input
              v-model="serviceRuleForm.nightFee"
              placeholder="夜间费"
              title="夜间费"
            ></el-input>
          </el-form-item>
          <el-form-item label="空跑费规则:" prop="emptyRunFeeRule">
            <e6-vr-select
              v-model="serviceRuleForm.emptyRunFeeRule"
              :data="emptyRunRuleTypeList"
              placeholder="空跑费规则"
              title="空跑费规则"
              clearable
              :props="{
                id: 'codeValue',
                label: 'codeName'
              }"
            ></e6-vr-select>
          </el-form-item>
          <el-form-item
            label="空跑费:"
            prop="emptyRunFee"
            v-show="serviceRuleForm.emptyRunFeeRule == 1"
          >
            <el-input
              v-model="serviceRuleForm.emptyRunFee"
              placeholder="空跑费"
              title="空跑费"
            ></el-input>
          </el-form-item>
          <el-form-item label="配件补贴费:" prop="partSubsidyFee">
            <el-input
              v-model="serviceRuleForm.partSubsidyFee"
              placeholder="配件补贴费"
              title="配件补贴费"
            ></el-input>
          </el-form-item>
        </el-form>

        <div class="dialogTitle">
          <div class="line"></div>
          <div class="til">上门费和路补配置</div>
        </div>
        <el-table border :data="configTableData" highlight-current-row>
          <el-table-column
            show-overflow-tooltip
            align="center"
            prop="address"
            label="省市区"
            min-width="230"
          >
            <template slot-scope="scope">
              <e6-vr-select
                v-if="scope.row.address != '其他'"
                :data="scope.row.addressList"
                ref="select"
                v-model="scope.row.address"
                @change="
                  (val, node) => {
                    changeAddress(val, node, scope.row);
                  }
                "
                title
                is-title
                :remote="true"
                @filterChange="
                  query => {
                    filterChange(query, scope.row);
                  }
                "
                @clear="
                  () => {
                    clearAddress(scope.row);
                  }
                "
                :search="true"
                :loading="loading"
                multiple
                clearable
                :props="{ id: 'fullName', label: 'fullName' }"
              >
              </e6-vr-select>
              <span v-else>{{ scope.row.address }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="roadSubsidyRule"
            label="路补规则"
            min-width="160"
          >
            <template slot-scope="scope">
              <e6-vr-select
                class="select-width-set"
                v-model="scope.row.roadSubsidyRule"
                :data="roadRepairRuletypeList"
                placeholder="路补规则"
                title="路补规则"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                @change="
                  (val, node) => {
                    changeRoadSubsidyRule(val, node, scope.row);
                  }
                "
              ></e6-vr-select>
            </template>
          </el-table-column>
          <!-- <el-table-column
            show-overflow-tooltip
            align="center"
            prop="roadRepairFee"
            label="路补单价"
            min-width="100"
          >
            <template slot-scope="scope">
              <el-input
                placeholder="请输入路补单价"
                v-model="scope.row.roadRepairFee"
                style="width:80px"
              ></el-input>
            </template>
          </el-table-column> -->
          <el-table-column
            show-overflow-tooltip
            align="center"
            prop="emptyRunDeduction"
            label="空跑扣除公里"
            min-width="100"
          >
            <template slot-scope="scope">
              <el-input
                placeholder="请输入"
                v-model="scope.row.emptyRunDeduction"
                style="width:80px"
                :disabled="scope.row.disabled"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            align="center"
            prop="notEmptyRunDeduction"
            label="非空跑扣除公里"
            min-width="100"
          >
            <template slot-scope="scope">
              <el-input
                placeholder="请输入"
                v-model="scope.row.notEmptyRunDeduction"
                style="width:80px"
                :disabled="scope.row.disabled"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            align="center"
            prop="roadSubsidy"
            label="路补一口价/非一口价单价"
            min-width="140"
          >
            <template slot-scope="scope">
              <el-input
                placeholder="请输入"
                v-model="scope.row.roadSubsidy"
                style="width:80px"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="reachFee"
            align="center"
            label="上门费"
            min-width="100"
          >
            <template slot-scope="scope">
              <el-input
                placeholder="请输入"
                v-model="scope.row.reachFee"
                style="width:80px"
              ></el-input>
            </template>
          </el-table-column>

          <el-table-column
            show-overflow-tooltip
            align="center"
            prop="date"
            label="操作"
            min-width="100"
          >
            <template slot-scope="{ row, $index }">
              <div class="icon">
                <i
                  class="e6-icon-add_line1"
                  title="新增"
                  @click="handleAdd"
                ></i>
                <i
                  class="e6-icon-delete_line1"
                  title="删除"
                  v-if="row.address != '其他'"
                  @click="deleteColumn($index)"
                ></i>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="物料价格表" name="2">
        <div class="dialogTitle">
          <div class="line"></div>
          <div class="til">基础信息</div>
        </div>
        <el-form label-width="100px" :model="basicInfoForm" :inline="true">
          <el-form-item label="服务商名称:" prop="a">
            {{ basicInfoForm.serviceProvider }}
          </el-form-item>
          <el-form-item label="服务商编号:" prop="a">
            {{ basicInfoForm.serviceNo }}
          </el-form-item>
        </el-form>
        <div class="dialogTitle">
          <div class="line"></div>
          <div class="til">规则配置</div>
        </div>

        <el-form
          class="search-list"
          ref="searchForm"
          :model="searchForm"
          :inline="true"
        >
          <el-form-item class="search-item--1" prop="materialNos">
            <e6-vr-select
              v-model="searchForm.materialNos"
              :data="totalTableData"
              placeholder="物料分类"
              title="物料分类"
              clearable
              virtual
              :is-title="true"
              multiple
              :props="{
                id: 'classifyNo',
                label: 'classifyName'
              }"
            ></e6-vr-select>
          </el-form-item>
          <el-form-item prop="materialTypes">
            <e6-vr-select
              v-model="searchForm.materialTypes"
              :data="slipperMaterialTypeList"
              placeholder="物料类型"
              title="物料类型"
              multiple
              clearable
              virtual
              :is-title="true"
              :props="{
                id: 'codeValue',
                label: 'codeName'
              }"
            ></e6-vr-select>
          </el-form-item>

          <el-form-item class="search-item--buttons">
            <el-button type="primary" @click="handleSearch">查询</el-button>
            <el-button class="reset" @click="handleReset">重置</el-button>
          </el-form-item>
        </el-form>

        <el-table border :data="tableData" highlight-current-row height="300px">
          <el-table-column
            show-overflow-tooltip
            align="center"
            prop="classifyName"
            label="物料分类"
            min-width="140"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            align="center"
            prop="materialTypeName"
            label="物料类型"
            min-width="100"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="installPrice"
            align="center"
            label="新装价格"
            min-width="140"
          >
            <template slot-scope="scope">
              <el-input
                placeholder="请输入"
                v-model="scope.row.installPrice"
                style="width:80px"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="repairPrice"
            align="center"
            label="维修价格"
            min-width="140"
          >
            <template slot-scope="scope">
              <el-input
                placeholder="请输入"
                v-model="scope.row.repairPrice"
                style="width:80px"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="addPrice"
            align="center"
            label="加装价格"
            min-width="140"
          >
            <template slot-scope="scope">
              <el-input
                placeholder=""
                v-model="scope.row.addPrice"
                style="width:80px"
                :disabled="scope.row.materialType == 1"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="unpackPrice"
            align="center"
            label="拆机价格"
            min-width="140"
          >
            <template slot-scope="scope">
              <el-input
                placeholder="请输入"
                v-model="scope.row.unpackPrice"
                style="width:80px"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="isReachFee"
            align="center"
            label="是否收上门费"
            min-width="140"
          >
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.isReachFee"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="searchForm.pageSizes"
          :total="searchForm.total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="sizes,prev,pager, next,total"
        ></el-pagination>
      </el-tab-pane>
    </el-tabs>

    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="confirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import {
  getServiceProviderListPage,
  findDownList,
  getAddressDownList,
  getMaterialClassifyList,
  saveServicePrice,
  getServicePriceAddress,
  getServiceMaterial,
  updateServicePrice
} from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "addPrice",
  components: {},
  data() {
    return {
      addressList: [],
      activeName: "1",
      dialogLoading: false,
      basicInfoForm: {
        serviceProvider: "",
        serviceId: "",
        serviceNo: "",
        feiShuContractNo: "",
        time: [],
        startTime: "",
        endTime: "",
        companyIdList: [],
        companyList: [],
        serviceRate: ""
      },
      basicInfoFormRules: {
        serviceNo: [
          {
            required: true,
            message: "请选择服务商",
            trigger: ["blur", "change"]
          }
        ],
        time: [
          {
            required: true,
            message: "请选择生效日期",
            trigger: ["blur", "change"]
          }
        ],
        // companyIdList: [
        //   {
        //     required: true,
        //     message: "请选择支付主体",
        //     trigger: ["blur", "change"]
        //   }
        // ],
        serviceRate: [
          {
            required: true,
            message: "请输入服务费率",
            trigger: ["blur", "change"]
          }
        ]
      },
      serviceRuleForm: {
        unpackFeeRule: "",
        unpackAndUnpackFee: "",
        notUnpackAndUnpackFee: "",
        repairFeeRule: "",
        repairFee: "",
        nightFee: "",
        emptyRunFeeRule: "",
        emptyRunFee: "",
        roadRepairFee: "",
        partSubsidyFee: ""
      },
      serviceRuleFormRules: {
        unpackFeeRule: [
          {
            required: true,
            message: "请选择拆机费规则",
            trigger: ["blur", "change"]
          }
        ],
        repairFeeRule: [
          {
            required: true,
            message: "请选择维修费规则",
            trigger: ["blur", "change"]
          }
        ],
        nightFee: [
          {
            required: true,
            message: "请输入夜间费",
            trigger: ["blur", "change"]
          }
        ],
        emptyRunFeeRule: [
          {
            required: true,
            message: "请选择空跑费规则",
            trigger: ["blur", "change"]
          }
        ],
        partSubsidyFee: [
          {
            required: true,
            message: "请输入配件补贴费",
            trigger: ["blur", "change"]
          }
        ]
      },
      configTableData: [
        {
          address: "其他",
          roadSubsidyRule: "",
          emptyRunDeduction: "",
          notEmptyRunDeduction: "",
          roadSubsidy: 0,
          reachFee: 0,
          addressDetailList: []
        }
      ],
      facilitatorList: [],
      companyList: [],
      basicPriceRuleTypeList: [],
      roadRepairRuletypeList: [],
      emptyRunRuleTypeList: [],
      tableData: [],
      totalTableData: [],
      searchForm: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        pageSizes: [10, 20, 50],
        materialNos: [],
        materialTypes: []
      },
      filterTableData: [],
      slipperMaterialTypeList: []
    };
  },
  props: {
    addVisible: {
      type: Boolean,
      default: false
    },
    addType: {
      type: Number,
      default: 1
    },
    clickRow: {
      type: Object,
      default: () => {}
    }
  },
  mixins: [base],
  computed: {
    visible: {
      get() {
        return this.addVisible;
      },
      set(val) {
        this.$emit("update:addVisible", val);
      }
    }
  },
  watch: {},
  created() {},
  methods: {
    async initData() {
      try {
        this.dialogLoading = true;
        let promiseList = [
          getServiceProviderListPage({ page: 1, pageSize: 999999 }),
          findDownList([
            "basicPriceRuleType",
            "roadRepairRuletype",
            "emptyRunRuleType",
            "serviceCompanyList",
            "slipperMaterialType"
          ])
        ];
        let [facilitatorRes, enmuRes] = await Promise.all(promiseList);
        //服务商
        this.facilitatorList = this.getFreezeResponse(facilitatorRes, {
          path: "data.array"
        });
        //一口价
        this.basicPriceRuleTypeList = this.getFreezeResponse(enmuRes, {
          path: "data.basicPriceRuleType"
        });
        //添加路补规则枚举
        this.roadRepairRuletypeList = this.getFreezeResponse(enmuRes, {
          path: "data.roadRepairRuletype"
        });
        //空跑费规则枚举
        this.emptyRunRuleTypeList = this.getFreezeResponse(enmuRes, {
          path: "data.emptyRunRuleType"
        });
        //物料分类
        this.slipperMaterialTypeList = this.getFreezeResponse(enmuRes, {
          path: "data.slipperMaterialType"
        });
        //主体
        this.companyList = this.getFreezeResponse(enmuRes, {
          path: "data.serviceCompanyList"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    limitInput(value, name) {
      this.basicInfoForm[name] =
        ("" + value) // 第一步：转成字符串
          .replace(/[^\d^.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
          .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
          .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
          .match(/^\d*(\.?\d{0,2})/g)[0] || "";
    },
    //编辑时查询详情
    async getDetail() {
      try {
        this.dialogLoading = true;
        let promiseList = [
          getServicePriceAddress({
            servicePriceId: this.clickRow.servicePriceId
          }),
          getServiceMaterial({
            servicePriceId: this.clickRow.servicePriceId
          })
        ];
        let [addressRes, priceRes] = await Promise.all(promiseList);
        let configTableData = addressRes.data;
        configTableData.map(item => {
          item.roadSubsidyRule = item.roadSubsidyRule
            ? item.roadSubsidyRule + ""
            : "";
          item.disabled =
            item.roadSubsidyRule == 1 || item.roadSubsidyRule == 3
              ? true
              : false;

          if (item.addressDetailList.some(ele => ele.fullName == "其他")) {
            item.address = "其他";
          } else {
            item.address = item.addressDetailList.map(ele => ele.fullName);
            item.addressList = item.addressDetailList;
          }
        });
        this.configTableData = configTableData;
        //所有的物料分类
        this.totalTableData = priceRes.data;
        this.totalTableData.map(item => {
          item.addPrice =
            item.materialType == 1 ? "" : item.addPrice ? item.addPrice : 0;
        });
        //过滤后的数据
        this.filterTableData = this.totalTableData;

        this.frontEndPageChange();
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },

    //点击查询
    handleSearch() {
      this.searchForm.pageIndex = 1;
      let tableData1 = this.searchForm.materialNos.length
        ? this.totalTableData.filter(v => {
            return this.searchForm.materialNos.includes(v.classifyNo);
          })
        : this.totalTableData;
      let tableData2 = this.searchForm.materialTypes.length
        ? tableData1.filter(v => {
            return this.searchForm.materialTypes.includes(
              v.materialType.toString()
            );
          })
        : tableData1;
      this.filterTableData = tableData2;
      this.frontEndPageChange();
    },
    //对数据进行分页处理
    frontEndPageChange() {
      let filterTableData = this.filterTableData;
      let start = (this.searchForm.pageIndex - 1) * this.searchForm.pageSize;
      if (start >= filterTableData.length) start = 0;
      let end = this.searchForm.pageIndex * this.searchForm.pageSize;
      if (end >= filterTableData.length) end = filterTableData.length;
      this.tableData = filterTableData.slice(start, end);
      this.searchForm.total = this.filterTableData.length;
    },
    //修改路补规则
    changeRoadSubsidyRule(val, node, row) {
      if (node.codeValue == 1 || node.codeValue == 3) {
        row.emptyRunDeduction = "";
        row.notEmptyRunDeduction = "";
        row.disabled = true;
      } else {
        row.disabled = false;
      }
    },
    // 改变分页数量
    handleSizeChange(val) {
      this.searchForm.pageSize = val;
      this.frontEndPageChange();
    },
    // 改变当前页
    handleCurrentChange(val) {
      this.searchForm.pageIndex = val;
      this.frontEndPageChange();
    },
    //打开弹框
    openDialog() {
      this.activeName = "1";
      this.initData();
      if (this.addType == 2) {
        let clickRow = _.cloneDeep(this.clickRow);
        this.basicInfoForm = {
          serviceProvider: clickRow.serviceProvider,
          serviceNo: clickRow.serviceNo,
          feiShuContractNo: clickRow.feiShuContractNo,
          time: [clickRow.startTimeStr, clickRow.endTimeStr],
          startTime: clickRow.startTimeStr,
          endTime: clickRow.endTimeStr,
          companyIdList: clickRow.companyRespList.map(item => item.companyNo),
          companyList: clickRow.companyRespList,
          serviceRate: clickRow.serviceRate,
          serviceId: clickRow.serviceId
        };
        let serviceRule = clickRow.serviceRule
          ? _.cloneDeep(clickRow.serviceRule)
          : {};
        this.serviceRuleForm = {
          ...serviceRule,
          unpackFeeRule: serviceRule.unpackFeeRule
            ? serviceRule.unpackFeeRule.toString()
            : "",
          repairFeeRule: serviceRule.repairFeeRule
            ? serviceRule.repairFeeRule.toString()
            : "",
          emptyRunFeeRule: serviceRule.emptyRunFeeRule
            ? serviceRule.emptyRunFeeRule.toString()
            : ""
        };
        this.getDetail();
      } else {
        this.getMaterialClassifyListReq();
        // this.loadDataAddress();
      }
      this.$nextTick(() => {
        this.$refs["basicInfoForm"].clearValidate();
        this.$refs["serviceRuleForm"].clearValidate();
      });
    },
    changeFacilitator(val, node) {
      this.basicInfoForm.serviceProvider = node.serviceProvider;
      this.basicInfoForm.serviceId = node.serviceId;
    },
    //添加行
    handleAdd() {
      let configTableData = _.cloneDeep(this.configTableData);
      configTableData.unshift({
        address: [],
        reachFee: 0,
        roadSubsidyRule: "",
        emptyRunDeduction: "",
        notEmptyRunDeduction: "",
        roadSubsidy: 0,
        addressDetailList: [],
        addressList: []
      });
      this.configTableData = configTableData;
    },
    //删除单行
    deleteColumn(index) {
      let configTableData = _.cloneDeep(this.configTableData);
      configTableData.splice(index, 1);
      this.configTableData = configTableData;
    },
    //选择主体
    changeCompany() {
      let companyList = this.$refs.companySelect.getChecked().node.map(item => {
        return Object.assign(
          {},
          {
            companyNo: item.id,
            companyName: item.label
          }
        );
      });
      this.basicInfoForm.companyList = companyList;
    },
    clearCompany() {
      this.basicInfoForm.companyList = [];
    },
    changeAddress(val, node, row) {
      if (val) {
        if (Array.isArray(node)) {
          //全选
          let addressList = row.addressList;
          let addressNodes = addressList.filter(itemA => {
            return node.indexOf(itemA.fullName) > -1;
          });
          let addressDetailList = [...row.addressDetailList, ...addressNodes];
          var obj = {};
          addressDetailList = addressDetailList.reduce(function(a, b) {
            obj[b.fullName] ? "" : (obj[b.fullName] = true && a.push(b));
            return a;
          }, []);
          row.addressDetailList = addressDetailList;
        } else {
          // 单个勾选
          if (
            row.addressDetailList.some(item => item.fullName == node.fullName)
          )
            return;
          row.addressDetailList.push(node);
        }
      } else {
        row.addressDetailList = row.addressDetailList.filter(
          item => item.fullName != node.fullName
        );
      }
    },
    clearAddress(row) {
      row.addressDetailList = [];
    },
    // 远程搜索实现示例
    filterChange(query, row) {
      console.log(query, row);
      if (query !== "") {
        this.data = [];
        this.loadDataAddress(query, row);
      }
    },
    //加载加载数据
    async loadDataAddress(val, row) {
      try {
        this.selectLoading = true;
        let res = await getAddressDownList({
          addressName: val,
          level: 3,
          pageIndex: 1,
          pageSize: 300
        });
        let addressList = _.cloneDeep(res.data.array).map(item => {
          return Object.assign(
            {},
            {
              provinceId: item.provinceCode,
              provinceName: item.provinceName,
              cityId: item.cityCode,
              cityName: item.cityName,
              countyId: item.countyCode,
              countyName: item.countyName,
              fullName: item.fullName
            }
          );
        });
        let allAddressList = [...row.addressDetailList, ...addressList];

        let flattenedArr = allAddressList.flatMap(item => item);
        var obj = {};
        flattenedArr = flattenedArr.reduce(function(a, b) {
          obj[b.fullName] ? "" : (obj[b.fullName] = true && a.push(b));
          return a;
        }, []);
        row.addressList = flattenedArr.filter(item => item.fullName != "其他");
        // let addressDetailList = configTableData.map(
        //   item => item.addressDetailList
        // );
        // let flattenedArr = addressDetailList.flatMap(item => item);
        // var obj = {};
        // flattenedArr = flattenedArr.reduce(function(a, b) {
        //   obj[b.fullName] ? "" : (obj[b.fullName] = true && a.push(b));
        //   return a;
        // }, []);
        // this.addressList = flattenedArr.filter(item => item.fullName != "其他");

        // this.getFreezeResponse(res, {
        //   path: "data.array"
        // });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    //获取物料分类
    async getMaterialClassifyListReq() {
      try {
        this.loading = true;
        let res = await getMaterialClassifyList({});
        let totalTableData = res.data.array;
        totalTableData.map(item => {
          item.isReachFee = item.isReachFee == 1 ? 1 : 0;
        });
        this.totalTableData = totalTableData;
        this.filterTableData = this.totalTableData;
        this.frontEndPageChange();
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //点击确定
    confirm() {
      //服务类型的校验
      const p1 = new Promise(resolve => {
        this.$refs["basicInfoForm"].validate(valid => {
          if (valid) resolve();
        });
      });
      //服务内容填写的校验
      const p2 = new Promise(resolve => {
        this.$refs["serviceRuleForm"].validate(valid => {
          if (valid) resolve();
        });
      });
      Promise.all([p1, p2]).then(() => {
        if (this.addType == 1) {
          this.saveServicePriceReq();
        } else {
          this.updateServicePriceReq();
        }
      });
    },
    //新增
    async saveServicePriceReq() {
      try {
        this.dialogLoading = true;
        let serviceAddressList = _.cloneDeep(this.configTableData);
        serviceAddressList.map(item => {
          if (item.address == "其他") {
            item.addressDetailList = [
              {
                addressId: 0,
                serviceAddressId: 0,
                provinceName: "其他",
                provinceId: 0,
                cityName: "其他",
                cityId: 0,
                countyName: "其他",
                countyId: 0,
                fullName: "其他"
              }
            ];
          }
        });
        let res = await saveServicePrice({
          ...this.basicInfoForm,
          startTime: this.basicInfoForm.time[0],
          endTime: this.basicInfoForm.time[1],
          serviceRule: this.serviceRuleForm,
          serviceAddressList,
          serviceMaterialList: this.totalTableData
        });
        if (res.code == "OK") {
          this.$message.success("新增成功");
          this.$emit("refresh");
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //编辑
    async updateServicePriceReq() {
      try {
        this.dialogLoading = true;
        let serviceAddressList = _.cloneDeep(this.configTableData);
        let res = await updateServicePrice({
          ...this.basicInfoForm,
          servicePriceId: this.clickRow.servicePriceId,
          startTime: this.basicInfoForm.time[0],
          endTime: this.basicInfoForm.time[1],
          serviceRule: this.serviceRuleForm,
          serviceAddressList,
          serviceMaterialList: this.totalTableData
        });
        if (res.code == "OK") {
          this.$message.success("编辑成功");
          this.$emit("refresh");
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //点击取消
    closeDialog() {
      this.visible = false;
      this.serviceRuleForm = {
        unpackFeeRule: "",
        unpackAndUnpackFee: "",
        notUnpackAndUnpackFee: "",
        repairFeeRule: "",
        repairFee: "",
        nightFee: "",
        emptyRunFeeRule: "",
        emptyRunFee: "",
        roadRepairFee: "",
        partSubsidyFee: ""
      };
      this.basicInfoForm = {
        serviceProvider: "",
        serviceNo: "",
        feiShuContractNo: "",
        time: [],
        startTime: "",
        endTime: "",
        companyIdList: [],
        companyList: [],
        serviceRate: "",
        serviceId: ""
      };
      this.totalTableData = [];
      this.filterTableData = [];
      this.tableData = [];
      this.configTableData = [
        {
          address: "其他",
          roadSubsidy: 0,
          reachFee: 0,
          roadSubsidyRule: "",
          emptyRunDeduction: "",
          notEmptyRunDeduction: "",
          addressDetailList: []
        }
      ];
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.add-concat-dialog {
  .el-dialog__body {
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    overflow-y: auto;
    max-height: 600px;
    .el-input {
      width: 220px;
    }
    .select-width-set {
      .el-input {
        width: 140px;
      }
    }
    .icon {
      i {
        margin-right: 10px;
        font-size: 20px;
        color: #46bfea;
      }
    }
    .dialogTitle {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      width: 100%;
      justify-content: flex-start;
      .line {
        width: 4px;
        height: 25px;
        background: #46bfea;
        margin-right: 5px;
      }
      .til {
        font-size: 16px;
        font-weight: 500;
        margin-right: 15px;
      }
      i {
        margin-right: 10px;
        font-size: 20px;
        color: #46bfea;
      }
    }
  }
}
</style>
